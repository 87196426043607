
.scrollContainerHorizontal .simplebar-content{
  display: flex !important;
}

.scrollContainerVertical {
  overflow-x: hidden;
}

.simplebar-track.simplebar-vertical{
  width: 8px !important;
}

.simplebar-track.simplebar-horizontal{
  height: 8px !important;
}

.hEndScroll .simplebar-content{
  justify-content: flex-end;
}

.simplebar-mask,
.scrollContainerVertical .simplebar-mask,
.scrollContainerHorizontal .simplebar-mask {
  z-index: unset !important;
}

.scrollContainerFullscreen .simplebar-content {
  display: flex;
  min-height: 100vh;
}
