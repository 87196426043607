* {
  font-family: Inter;
}

body {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.highlightHover:hover,
.highlightHover.active {
  background: #48bb78;
}

#root {
  display: flex;
  flex: 1;
}

/* segmented control overwrites */

label {
  margin: 0 !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 10000;
}

/* react-confirm-alert */

.react-confirm-alert-overlay {
  z-index: 1000 !important;
  animation-duration: 0.2s !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

/** Autocomplete **/

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* react-virtualized overwrites */

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 0 !important;
}

.ReactVirtualized__Table__headerTruncatedText {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  color: #467b3e;
}

/* HOTFIX */

.bg_success input {
  background: #48bb78 !important;
}

.bg_warning input {
  background: #FDF483 !important;
}

.bg_danger input {
  background: #f56565 !important;
}

.Toastify__toast-container {
  z-index: 20000 !important;
}
