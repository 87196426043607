.dataItemTable {
  border-spacing: 0;
  width: 100%;
}

.dataItemTable thead {
  border-bottom: 4px solid #2e2e2e;
}

.dataItemTable th:not(:last-child), .dataItemTable td:not(:last-child) {
  border-right: 8px solid transparent;
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid black;
}

.ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__rowColumn>* {
  height: 100% !important;
}

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0 !important;
}

.dataItemTable tbody tr:hover {
  background-color: #daf1e4;
  cursor: pointer;
}

.selectedDataItem {
  background-color: #ffa500 !important;
}

.activeDataItem {
  background-color: #cdd500 !important;
}

.disableSelection * {
  user-select: none !important;
}

.ReactVirtualized__Table__headerRow {
  text-transform: none !important;
}
